/*
 * Request user authorization
 */
import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import jwt from 'jsonwebtoken'
import { getAuthSuccess, getAuthError, logoutSuccess } from './auth.slice'
import { setAuthDataSuccess, setAuthDataError } from './authData.slice'
import {
  resetUserData,
  setUserDataSuccess,
  setUserSettingsStakes,
} from '../user/user.slice'
import { userLoggedOut } from '../user/login.slice'
import {
  ID_TOKEN,
  USER_DATA,
  TOKEN_EXP,
  USERID,
  OPERATORID,
} from '../../config/app.config'
import { menuController } from '@ionic/core'
import { setB2C } from '../B2C/b2c.slice'
import { CONFIG } from '../../config/api.config'
import { toastr } from 'react-redux-toastr'

// update token if user places a new bet
const updateToken = (token) => async (dispatch) => {
  try {
    if (!token) throw new Error('Response has no token')

    const decoded = jwt.decode(token, { complete: true })
    dispatch(getAuthSuccess(token))
    dispatch(setAuthDataSuccess(token))
    localStorage.setItem(ID_TOKEN, token)
    localStorage.setItem(TOKEN_EXP, `my +${token}`)
  } catch (e) {
    dispatch(getAuthError())
    dispatch(setAuthDataError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}

// application/x-www-form-urlencoded

export const requestAuth = (data, ip) => async (dispatch) => {
  // Convert data object to URL-encoded string
  const formData = new URLSearchParams()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  // Base64 encode the credentials for Basic Authentication
  const credentials = Buffer.from('your_client_id:your_client_secret').toString(
    'base64'
  )
  const authHeader = `Basic ${credentials}`
  const authhard = 'Basic YXBwbGljYXRpb246c2VjcmV0'
  try {
    const response = await httpNotAuth.post(
      `/user/userLogin?ip=${ip}`,
      formData.toString(),
      {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: authhard,
        },
      }
    )
    if (response?.data?.status) {
      const userData = response?.data?.data

      if (userData?.is_demo) {
        userData.user_name = 'Demo'
        userData.name = 'Demo'
      }
      // if (userData?.settings?.oneClickSettings?.active) userData.settings.oneClickSettings.active = false;

      dispatch(setUserDataSuccess(userData))
      // //saved user data
      // console.log("Login Data", response?.data)
      localStorage.setItem(USER_DATA, JSON.stringify(userData))
      localStorage.setItem(USERID, response.data?.data?._id)
      localStorage.setItem(OPERATORID, response.data?.operatorId)
      // dispatch(
      //   setB2C(response?.data?.result.isB2C || false)
      // );
      localStorage.setItem('B2C', response?.data?.result?.isB2C || false)
      localStorage.setItem('isLogin', true)

      // try {
      //   var loginName = userData.loginName;

      //   if (loginName.includes(".")) {
      //     loginName = loginName.split(".")[1];
      //   }

      //   if (loginName.includes("_")) {
      //     loginName = loginName.split("_")[1];
      //   }

      //   if (CONFIG.appName === "LOTUS 365" || window.document.location.hostname.includes('localhost')) {
      //     window.intercomSettings = {
      //       api_base: "https://api-iam.intercom.io",
      //       app_id: "p8qbzn7b",
      //       name: loginName, // Full name
      //       user_id: userData.memberCode
      //     };
      //     window.Intercom("update");
      //   } else {
      //     window.intercomSettings = {
      //       api_base: "https://api-iam.intercom.io",
      //       app_id: "wtcglvc8",
      //       name: loginName, // Full name
      //       user_id: userData.memberCode
      //     };
      //     window.Intercom("update");
      //   }

      // } catch (e) {
      //   console.log(e);
      // }

      // try {
      //   // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

      //   // To set unique user id in your system when it is available
      //   window.fcWidget.setExternalId(userData.loginName.includes(".") ? userData.loginName.split(".")[1] : userData.loginName);

      //   // To set user name
      //   // window.fcWidget.user.setFirstName('John')

      //   // To set user properties
      //   window.fcWidget.user.setProperties({
      //     plan: "Pro",                 // meta property 1
      //     status: "Active"                // meta property 2
      //   });
      // } catch (e) {
      // }

      if (!response?.data?.token?.accessToken)
        throw new Error('Response has no token')
      dispatch(updateToken(response?.data?.token?.accessToken))
    }
    if (
      localStorage.getItem('page') &&
      localStorage.getItem('page').includes('sportsbook')
    ) {
      // localStorage.setItem('page', '/default-page')
      // localStorage.removeItem('page')
      window.location.reload()
    }
    return response
  } catch (e) {
    dispatch(getAuthError())
    //TODO: handle this
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestAuthDemo = (data) => async (dispatch) => {
  // Convert data object to URL-encoded string

  try {
    const response = await httpNotAuth.post('user/autoDemoUserLogin', {})
    if (response?.data?.status) {
      requestAuth(response?.data.data)
    }

    return response
  } catch (e) {
    dispatch(getAuthError())
    //TODO: handle this
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestStack = (data) => async (dispatch) => {
  // Convert data object to URL-encoded string
  try {
    const response = await httpAuth.post('/user/getUserMatchStack', {})
    if (response?.data?.status) {
      dispatch(setUserSettingsStakes(response?.data?.match_stack))
    }
    return response
  } catch (e) {
    //TODO: handle this
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestLogout = () => async (dispatch) => {
  try {
    menuController.close()

    dispatch(userLoggedOut())
    dispatch(logoutSuccess())
    dispatch(resetUserData())
    dispatch(setAuthDataError())

    localStorage.removeItem(ID_TOKEN)
    localStorage.removeItem(TOKEN_EXP)
    localStorage.removeItem(USER_DATA)
    localStorage.removeItem('B2C')
    localStorage.removeItem('waId')
    window.location.reload()
  } catch (e) {
    dispatch(getAuthError())
  }
}

export const requestContinueSession = (data) => async (dispatch) => {
  try {
    const response = await httpAuth.post('/auth/keep-alive', data)
    if (response.headers['authorization']) {
      dispatch(updateToken(response.headers['authorization']))
    }
    // await httpAuth.post('/timing', response.headers)
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestAuthGoogle = (data) => async (dispatch) => {
  try {
    const response = await httpAuth.post('/auth/glogin', data)
    if (response?.data?.success) {
      const userData = response?.data?.result
      if (userData?.settings?.oneClickSettings?.active)
        userData.settings.oneClickSettings.active = false

      dispatch(setUserDataSuccess(userData))
      localStorage.setItem(USER_DATA, JSON.stringify(userData))
      // dispatch(
      //   setB2C(response?.data?.result.isB2C || false)
      // );
      localStorage.setItem('B2C', response?.data?.result?.isB2C || false)
      localStorage.setItem('isLogin', true)

      if (!response.headers['authorization'])
        throw new Error('Response has no token')
      dispatch(updateToken(response.headers['authorization']))
    }
    if (
      localStorage.getItem('page') &&
      localStorage.getItem('page').includes('sportsbook')
    ) {
      // localStorage.setItem('page', '/default-page')
      // localStorage.removeItem('page')
      window.location.reload()
    }
    return response
  } catch (e) {
    dispatch(getAuthError())
    //TODO: handle this
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const whatsAppReq = (id) => async (dispatch) => {
  try {
    const response = await httpAuth.post(`/auth/walogin`, {
      otp: id,
    })
    if (response?.data?.success) {
      const userData = response?.data?.result
      if (userData?.settings?.oneClickSettings?.active)
        userData.settings.oneClickSettings.active = false

      dispatch(setUserDataSuccess(userData))
      localStorage.setItem(USER_DATA, JSON.stringify(userData))
      // dispatch(
      //   setB2C(response?.data?.result.isB2C || false)
      // );
      localStorage.setItem('B2C', response?.data?.result?.isB2C || false)
      localStorage.setItem('isLogin', true)

      if (!response.headers['authorization'])
        throw new Error('Response has no token')
      dispatch(updateToken(response.headers['authorization']))
    }
    window.location.href = window.location.href.split('?')[0]
    // localStorage.removeItem('waId')
    return response.data
  } catch (e) {
    window.location.href = window.location.href.split('?')[0]
  }
}

export const requestAuthVerification = (data) => async (dispatch) => {
  // Convert data object to URL-encoded string
  try {
    const response = await httpAuth.get('/user/validateToken')
    return response
  } catch (e) {
    //TODO: handle this
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}
