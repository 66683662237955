import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getBalanceError, getBalanceSuccess } from './balance.slice'

export const requestBalance = (memberCode) => async (dispatch) => {
  try {
    const response = await httpAuth.post(`/user/getBalanceCRef`)
    if (response.data.status) {
      dispatch(getBalanceSuccess(response.data.data))
    }
    return response
  } catch (e) {
    if (e.response && e.response.status === 401) {
     localStorage.clear();
     window.location.assign('/default-page')
    } else {
      dispatch(getBalanceError())
    }
  }
}

export const changeWalletStatus = (memberCode, bool, walletCode) => async (dispatch) => {
  try {
    const response = await httpAuth.put(`/account/${memberCode}/settings/switch-wallet?useBonusWallet=${bool}&walletCode=${walletCode}`)
    if (response.data.success) {
      console.log(response.data)
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}
